.slick-slider {
  margin-bottom: 2rem !important;

  @include breakpoint(medium) {
    margin-bottom: 0 !important;
  }
}

.slick-prev,
.slick-next {
  height: 3rem !important;
  width: 3rem !important;
}

.slick-prev:before,
.slick-next:before {
  background-color: $white !important;
  border: 2px solid;
  border-radius: 5px;
  color: #87AECC !important;
  display: block;
  font: normal normal normal 1.5rem/2.75rem FontAwesome !important;
  height: 3rem;
  width: 3rem;
}

.slick-prev {
  left: -5rem !important;

  &::before {
    content: '\f053' !important;
  }
}

.slick-next {
  right: -5rem !important;

  &::before {
    content: '\f054' !important;
  }
}

.slick-dots {
  position: relative !important;
}

.slick-dots li button::before {
  font-size: 1rem !important;
}
