.accordion-wrapper {
  display: flex;
  margin-bottom: 3 * $global-margin;
}

.accordion {
  @include accordion-container;
}

.accordion-wrapper .accordion-item {
  @include accordion-item;
}

.accordion-wrapper .accordion-title {
  @include accordion-title;

  font-size: 28px;
  padding: 0.75rem 1rem;
}

.accordion-wrapper.accordion-wrapper--simple .accordion-title {
  color: $body-font-color;
  font-size: 16px;
}

.accordion-wrapper .accordion-content {
  @include accordion-content;

  p,
  ul {
    line-height: 1.7;
  }
}

.accordion-wrapper:not(.accordion-wrapper--simple) .accordion-content {
  .heading,
  p,
  ul {
    color: $white;
  }
}

.accordion-wrapper .tabs {
  @include tabs-container;
  @include tabs-container-vertical;

  font-size: 28px;

  li {
    @include tabs-title;

    clear: left;
    margin-bottom: 0.25rem;
    text-align: right;
    width: 100%;

    a {
      display: inline-block;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

.accordion-wrapper:not(.accordion-wrapper--simple) .tabs {
  min-width: 15rem;

  li {
    a {
      color: rgba($white, 0.5);
      color: $white;
      font-size: 28px;
      padding: 0.75rem 2rem 0.75rem 1rem;
      transition: background-color 0.5s ease;
      white-space: nowrap;
    }

    &:not(.is-active) a {
      padding-right: 0;

      &::after {
        //animation: pulse 2s ease infinite;
        animation: pulse 2s ease infinite;

        border-color: transparent transparent transparent $primary-color;
        border-style: solid;
        border-width: 0 0 0 0;

        content: '+';
        display: inline-block;
        text-align: center;
        width: 2rem;

        @keyframes pulse {
          0% {
            //text-shadow: 0 0 5px rgba($primary-color-dark, 0.5);
            text-shadow: 0 0 1px rgba(white, 0.75);
          }

          20% {
            //text-shadow: 0 0 10px rgba(black, 0.75);
            text-shadow: 0 0 8px rgba(white, 1);
          }

          40% {
            //text-shadow: 0 0 15px rgba(black, 0.8);
            text-shadow: 0 0 15px rgba(white, 1);
          }

          60% {
            //text-shadow: 0 0 15px rgba(black, 0.8);
            text-shadow: 0 0 15px rgba(cyan, 1);
          }

          80% {
            //text-shadow: 0 0 20px rgba($primary-color-dark, 0);
            text-shadow: 0 0 25px rgba(cyan, 1);
          }

          95% {
            //text-shadow: 0 0 5px rgba($primary-color-dark, 0.05);
            text-shadow: 0 0 100px rgba(cyan, 1);
          }
        }
      }
    }

    &:hover,
    &.is-active {
      a {
        background-color: $white;
        color: $primary-color;

        &::after {
          border-color: transparent transparent transparent $secondary-color;
          border-style: solid;
          border-width: 0.5rem 0 0.5rem 1rem;
          color: transparent;
          height: 0;
          overflow: hidden;
          margin-left: 0.5rem;
          //transition: border 0.5s ease;
          width: 1.5rem;
        }
      }
    }
  }
}

.accordion-wrapper .tabs-panel {
  @include tabs-panel;
}

.accordion-wrapper .tabs-content {
  @include tabs-content;
  @include tabs-content-vertical;
}

.accordion-wrapper:not(.accordion-wrapper--simple) {
  position: relative;

  &::after {
    background-image: url('../../files/assets/img/person-02.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    bottom: -65px;
    content: '';
    display: none;
    height: auto;
    min-height: 700px;
    position: absolute;
    right: -6rem;
    top: 0;
    width: 450px;

    @include breakpoint(large) {
      display: block;
    }

    @include breakpoint(1280) {
      &::after {
        background-position: right 0;
        bottom: auto;
        height: 700px;
        min-height: 0;
        top: -40px;
      }
    }
  }

  .flex-container {
    position: relative;

    @include breakpoint(medium) {
      align-items: flex-start;
    }

    @include breakpoint(large) {
      & > div:first-child {
        max-width: 60%;
        padding: 1rem 2rem 0 3rem;
      }
    }
  }

  .heading,
  p,
  ul {
    color: $white;
    line-height: 1.7;
  }
}

.stat-box {
  align-self: flex-start;
  background: rgba($white, 0.65);
  color: $primary-color;
  font-weight: 600;
  margin-bottom: 1rem;
  max-width: 300px;
  padding: 0.25rem 1rem 1rem;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 20;

  @include breakpoint(medium) {
    border-radius: 3px;
    margin-left: 1rem;
    min-width: 220px;
  }

  &:first-child {
    @include breakpoint(large) {
      margin-left: 0;
      margin-top: 25rem;
    }

    @include breakpoint(1280) {
      margin-top: 15rem;
    }
  }

  strong {
    color: $white;
    display: block;
    font-size: 48px;
    text-shadow: 1.5px 1.5px 0 rgba($primary-color, 1);
  }
}
