@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}




%heading {
  @include fluid-type(
    320px,
    map-get($breakpoints, medium),
    16px,
    40px
  );

  font-weight: 600;
  margin-bottom: 1em;

  @include breakpoint(medium) {
    margin-bottom: 2rem;
  }
}

.heading {
  @extend %heading;
}

.heading--medium {
  @include fluid-type(
    320px,
    map-get($breakpoints, medium),
    16px,
    24px
  );

  margin-bottom: 1rem;
}

.heading--small {
  font-size: rem-calc(16);
  margin-bottom: 1rem;
}

.font-regular {
  font-weight: 400;
}

.callout-inline {
  color: #FFCE08;
  display: block;
}

.callout-circle {
  align-items: center;
  background-color: #FFCE08;
  border-radius: 50%;
  display: inline-flex;
  font-size: 1rem;
  height: 5.5rem;
  line-height: 1;
  margin-bottom: -3rem;
  margin-top: 2rem;
  text-align: center;
  transform: translate(0, -2rem) rotate(25deg);
  width: 5.5rem;
}

.uppercase {
  text-transform: uppercase;
}

q,
blockquote {
  font-weight: 300;
}

cite {
  font-style: normal;
  font-weight: 600;
}

a {
  color: $primary-color-dark;
  font-weight: 600;

  &:hover {
    color: $primary-color-medium;
  }
}

a[href^="tel"] {
  color: inherit;
  cursor: default;

  .whatinput-types-mouse & {
    pointer-events: none;
  }
}

small {
  display: inline-block;
  line-height: 1.5;

  .heading & {
    font-size: 15px;
    line-height: 1.8;
  }
}

.fa + * {
  margin-left: 0.25em;
}
