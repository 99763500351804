.sticky-container {
  z-index: 100;
}

.sticky.is-anchored {
  position: absolute;
}



.toggle-button {
  border: 0;
  box-shadow: none;
  left: calc(100% + 1.25rem);
  position: absolute;
  top: 0;
  padding: 0.5rem 1rem;
  padding: 0;
  padding-left: 0.75rem;
  transition: transform 0.5s ease;

  &:focus,
  &:hover {
    border: 0;
    box-shadow: none;
  }

  img {
    height: 50px;
    width: 50px;
    max-width: none;
  }

  .extend-content + [id="left"] & {
    transform: rotateY(180deg);
  }
}


.reveal iframe {
  height: 100%;
  min-height: 90vh;
  width: 100%;
}
