.author-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.author {
  margin: 0.5rem 2rem;

  @include breakpoint(medium) {
    margin-top: 2rem;
    width: 50%;
  }
}

.author .user-pic {
  margin-bottom: 2rem;
}
