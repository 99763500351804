.toc-list {
  list-style: none;
  margin: 0 0 2 * $global-margin;
  max-width: 25rem;
  width: 100%;
}

.toc-list__item {
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  margin: 1.25rem 0;
  position: relative;

  &::before {
    border-bottom: 0.25em dotted #666;
    bottom: 0.5rem;
    content: "";
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.toc-list__chapter {
  background-color: white;
  padding-right: 0.5rem;
  position: relative;
}

.toc-list__page {
  background-color: white;
  padding-left: 0.5rem;
  position: relative;
}
