figure {
  display: block;

  img {
    margin-bottom: 0.5rem;
    text-align: center;
  }
}

figcaption {
  font-style: italic;
  font-weight: 400;
}




table {
  @include table(even);
  @include table-hover;
}




[type="checkbox"] ~ a,
[type="checkbox"] ~ label {
  display: inline-block;
  font-size: 12px;
  line-height: 1.4;
  margin-right: 0;
}

label {
  color: #777;
}

textarea {
  min-height: 8.5rem;
  resize: vertical;
}




ol ol,
ul ul {
  margin-bottom: 0.3rem;
  margin-top: 0.15rem;
}

ul ul {
  list-style-type: circle;
}

dd + dt {
  margin-top: 1rem;
}
