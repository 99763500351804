.documents {
}

.documents__download {
  border: 1px solid $medium-gray;
  border-radius: 3px;
  display: block;
  margin-bottom: 0.75rem;
  min-height: calc(2rem + 50px);
  padding: 1rem;
  padding-right: calc(50px + 1rem);
  position: relative;
  transition: border-color 0.5s ease;

  &::after {
    background-image: url('../../files/assets/img/download.svg');
    background-size: contain;
    bottom: 1rem;
    content: '';
    display: block;
    height: 50px;
    margin: auto;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 50px;
  }

  &:hover {
    border-color: $primary-color;
  }
}

.documents__download-section,
.documents__download-title {
  line-height: 1.4;
  margin: 0;
}

.documents__download-section {
  color: $dark-gray;
}

.documents__download-title {
  @extend .heading--medium;
}
