.button {
  @include button();

  font-family: $body-font-family;
  font-size: rem-calc(17);
  font-weight: 600;
}

.button--hollow {
  @include button-hollow();

  border-color: $secondary-color;
  box-shadow: 0 0 0 1px $secondary-color inset;
  color: $secondary-color;

  &:hover,
  &:focus {
    border-color: scale-color($secondary-color, $lightness: -15%);
    box-shadow: 0 0 0 1px scale-color($secondary-color, $lightness: -15%) inset;
    color: scale-color($secondary-color, $lightness: -15%);
  }
}

.button--no-border {
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  color: $secondary-color;

  &:hover,
  &:focus {
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0);
    color: scale-color($secondary-color, $lightness: -15%);
    text-decoration: underline;
  }
}

.button--as-link {
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  color: $anchor-color;
  font-weight: 400;
  margin: 0;
  padding: 0;

  &:hover {
    background-color: transparent;
    color: $anchor-color-hover;
  }
}

.button--inverse {
  border-color: $white;
  color: $white;

  &:hover {
    border-color: scale-color($white, $lightness: -15%);
    color: scale-color($white, $lightness: -15%);
  }

  &.button--hollow {
    box-shadow: 0 0 0 1px $white inset;

    &:hover {
      box-shadow: 0 0 0 1px scale-color($white, $lightness: -15%) inset;
    }
  }
}


p + .button {
  margin-top: 2rem;
}
