// sass-lint:disable mixins-before-declarations

.no-bullet {
  line-height: 1.4;
}

.callout-list {
  font-weight: 700;
  margin-left: 1.5rem;

  &.callout-list--column {
    @include breakpoint(large) {
      column-count: 2;
      list-style: none;
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;

      li {
        margin-bottom: .25rem;

        &::before {
          content: '\2022';
          display: inline-block;
          font-size: 1.25rem;
          margin-right: 0.6rem;
          margin-top: -0.25rem;
          vertical-align: middle;
        }
      }
    }
  }
}

.checkmark-list {
  list-style: none;
}

.checkmark-list.card__section {
  margin: 0;
  padding: 0;
}

.checkmark-list__item {
  &::before {
    // Add a white border on the bottom and left, creating that 'L'
    border: solid;
    border-width: 0 2px 2px 0;
    content: '';
    display: inline-block;
    height: 0.75em;
    margin-left: -1.35em;
    margin-right: 1em;
    transform: rotate(45deg); // Rotate the L 45 degrees to turn it into a checkmark
    width: 0.35em;
  }
}

.checkmark-list.card__section .checkmark-list__item {
  padding: 0.25rem 3rem;

  &:first-child {
    padding-top: 1rem;
  }

  &:last-child {
    padding-bottom: 1rem;
  }
}

.checkmark-list__item--special {
  background-color: rgba($primary-color, 0.15);
}

.checkmark-list.card__section .checkmark-list__item--special {
  padding: 1rem 3rem 0;
}

.dropdown-pane {
  border: 0;
  border-radius: 0;
  left: 0;
  margin: 0 auto;
  max-width: 1200px;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  transition: opacity 0.5s ease;
  visibility: hidden;
  width: auto;

  @include breakpoint(medium) {
    top: 132px;
    width: 100%;
  }

  &.dropdown-pane--open {
    opacity: 1;
    visibility: visible;
  }

  &::before {
    background: rgba(white, 0.85);
    content: "";
    display: block;
    height: calc(100vh - 72px - 60px);
    left: -50vw;
    position: absolute;
    top: 0;
    width: 200vw;
    z-index: -2;
  }

  .heading {
    background-color: $primary-color-dark;
    background-color: rgba($primary-color-dark, 0.3);
    background-color: rgba($primary-color-light, 0.33);
    color: $white;

    background-color: rgba(white, 0.9);
    color: $primary-color-dark;

    display: flex;
    flex-direction: column-reverse;
    font-size: $global-font-size;
    font-weight: 400;
    justify-content: center;
    line-height: 1.2;
    margin-bottom: 1rem;
    min-height: 3.25rem;
    padding: 0.25rem;
    padding-left: 2.7rem;
    position: relative;
    text-align: left;
    width: 100%;

    &::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: inline-block;
      height: 100%;
      left: 0.6rem;
      position: absolute;
      top: 0;
      width: 1.5rem;
    }
  }

  .dropdown-pane__rubric {
    position: relative;

    &.dropdown-pane__rubric--1 .heading::before {
      display: none;
    }

    &.dropdown-pane__rubric--2 .heading::before {
      background-image: url('../../files/assets/img/menu/nav-expertenstandards.svg');
    }

    &.dropdown-pane__rubric--3 .heading::before {
      background-image: url('../../files/assets/img/menu/nav-zentrale-pflegethemen.svg');
    }

    &.dropdown-pane__rubric--4 .heading::before {
      background-image: url('../../files/assets/img/menu/nav-recht-organisation.svg');
    }

    &.dropdown-pane__rubric--5 .heading::before {
      background-image: url('../../files/assets/img/menu/nav-pflegerische-praxis.svg');
    }
  }

  .row {
    background-color: $primary-color-dark;
    box-shadow: 0 4px 3px -1px rgba(#555, 0.95);
    padding-top: 0.7rem;
    position: relative;
    z-index: 20;

    .column {
      @include grid-column-gutter(10);

      @include breakpoint(medium) {
        transition: padding 0.5s ease;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      @include breakpoint(1280) {
        @include grid-column-gutter(15);

        &:first-child {
          padding-left: 15px;
        }

        &:last-child {
          padding-right: 15px;
        }
      }
    }
  }

  ul {
    line-height: 1.25;
    list-style: none;
    margin-bottom: 1rem;
    margin-left: 0;

    li {
      border: 1px solid rgba($white, .25);
      border-width: 0 0 1px 1px;
      display: block;
      font-weight: 300;
      margin-bottom: 1.25rem;
      transition: border .25s ease;

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        border-color: rgba($white, 1);
        border-color: #FFCE08;
        border-width: 0 0 2px 2px;
        position: relative;

        a {
          color: #FFCE08;
          color: white;
          font-weight: 600;

          &::after {
            color: #FFCE08;
            content: '✓';
            display: inline-block;
            font-size: 1.25rem;
            margin-left: 0.25em;
            position: absolute;
            right: 4px;
            top: 1px;
          }
        }
      }

      &:not(.active) {
        border-color: $primary-color;
        color: $primary-color;
        opacity: 0.7;

        &:hover {
          border-color: rgba(#FFCE08, 0.8);
        }
      }

      a {
        color: $white;
        display: flex;

        $minView: 1024;
        $maxView: 1280;
        font-size: 15px; // fallback
        @include fluid-type(320px, map-get($breakpoints, medium), 14px, 16px);

        min-height: 3.25em;
        padding: 0.25rem 0.5rem;

        @include breakpoint($maxView) {
          min-height: 3.7rem;
          padding: 0.4rem 1.15rem 0.7rem 1rem;
          padding: 0.25rem 0.5rem 0.25rem 0.5rem;
        }

        i {
          display: inline-block;
          margin-left: 1rem;
          margin-right: -0.65rem;
          margin-top: 0.2rem;
          transform: rotate(90deg);
          transform-origin: 0 0;
        }
      }
    }
  }
}

.subnav-list {
  @include menu-base;
  @include menu-direction(vertical);

  font-weight: 600;
  margin: 0 0 2rem;
  text-transform: uppercase;

  @include breakpoint(large) {
    @include menu-direction(horizontal);
    justify-content: space-between;
    margin-bottom: 4rem;
  }

  .active {
    pointer-events: none;
  }

  .active a,
  li:hover a {
    color: inherit;
  }
}

.flex-list {
  @include menu-simple();

  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;

  li {
    vertical-align: baseline;
  }
}

.inline-list {
  margin: 0;

  > li,
  .inline-list__item {
    display: inline-block;
    margin-left: 0;
    margin-right: 1rem;
    vertical-align: baseline;

    @include breakpoint(medium) {
      line-height: 1;
    }

    > a {
      padding: 0;
    }
  }

  .inline-list__item--special {
    > a {
      font-weight: 600;
    }
  }
}

.inline-list--separation-line {
  > li {
    > a {
      &::after {
        content: '|';
        display: inline-block;
        margin-left: 1rem;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .inline-list__item--special {
    > a {
      &::after {
        color: $white;
        font-weight: 400;
      }
    }
  }
}

.argument-list {
  text-align: center;
  width: 100%;

  .slick-slide {
    padding: 0 1rem;
    position: relative;

    &::before,
    &::after {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
    }

    &::before {
      display: block;
      height: 5rem;
      margin: 0 auto 1rem;
      width: 5rem;
    }

    &[data-slick-index='0'],
    &[data-slick-index='5'] {
      &::before {
        background-image: url('../../files/assets/img/menu/icon-praxiswissen.svg');
      }
    }

    &[data-slick-index='1'],
    &[data-slick-index='6'] {
      &::before {
        background-image: url('../../files/assets/img/menu/icon-arbeitshilfen.svg');
      }
    }

    &[data-slick-index='-3'],
    &[data-slick-index='2'],
    &[data-slick-index='7'] {
      &::before {
        background-image: url('../../files/assets/img/menu/icon-schulungen-team.svg');
      }
    }
  }
}
