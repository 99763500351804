// Container classes
.container {
  @include grid-column-row();

  // chaining .container 3x to make sure foundation styles don't float this anywhere
  &#{&}#{&} {
    float: none;
  }

  // fix for counting pseudo-elements into space-between
  &.flex-container {
    &::before,
    &::after {
      display: none;
    }
  }
}

.flex-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.flex-container--medium-unstack {
  flex-direction: column;

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

// User Pics for Blogs, Quotes etc
.user-pic {
  border-radius: 50%;
}

.user-pic--author {
  background: #EFEFEF;
  height: 130px;
  padding: 25px 0 0;
  width: 130px;
}

.user-pic--float {
  float: left;
  margin: 0.5rem 0.65rem 1.5rem 0;
  shape-margin: 0.25rem;
  shape-outside: circle();

  @include breakpoint(medium) {
    margin: 0.75rem 1rem 1.25rem 0;
    shape-margin: 0;
    shape-outside: none;
  }

  & + * {
    margin-left: 0;
    margin-top: 0;
    padding-left: 0;
    padding-top: 0;
  }
}

.float-left {
  margin: 0.5rem 0.65rem 0.5rem 0;

  @include breakpoint(medium) {
    margin: 0.75rem 1rem 0.25rem 0;
  }
}

.customer-quotes {
  blockquote {
    margin: 0;
    padding: 0;

    @include breakpoint(medium) {
      padding-right: 100px;
    }
  }
}

// Change a margin
.margin-top {
  margin-top: 3 * $global-margin;
}

.margin-top-medium {
  @include breakpoint(medium) {
    margin-top: 3 * $global-margin;
  }
}

.margin-bottom {
  margin-bottom: 3 * $global-margin;
}

.margin-null {
  margin: 0;
}

.margin-null-medium {
  @include breakpoint(medium) {
    margin-boottom: 0;
    margin-top: 0;
  }
}
