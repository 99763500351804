.logo {
  height: 50px;
  width: auto;
}

.logo-title {
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0.75rem;
  margin-right: 0.5rem;
  vertical-align: bottom;
}

.sticky.is-stuck {
  .mainlogo {
    transform: translate(0, 0);
  }
}

.title-bar-left {
  flex-grow: 2;
}

.title-bar-right {
  flex-grow: 0;
  min-width: 2.5rem;
  padding: 0 0.25rem;
}

.mainlogo {
  @include breakpoint(small only) {
    display: block;

    .logo {
      display: block;
      height: auto;
      width: 100%;
    }
  }
}

.menu {
  @include menu-base;
}

.menu--vertical {
  @include menu-direction(vertical);
}
