.card {
  @include card-container();
}

.card__divider {
  @include card-divider();

  color: $white;
}

.card__divider + .card__divider {
  border-top: 1px solid rgba($white, 0.5);
}

.card__divider {
  position: relative;

  input[type='radio'] {
    float: left;
    margin-top: 0.25rem;

    & + strong {
      float: left;
      line-height: 1.4;
      padding-left: 1rem;

      &::before {
        background-color: $white;
        border: 2px solid #FFCE08;
        content: '';
        height: 1.5rem;
        left: 1rem;
        position: absolute;
        top: 1rem;
        width: 1.5rem;
      }
    }

    &:checked + strong {
      &::before {
        background-color: #FFCE08;
      }

      &::after {
        // Add a white border on the bottom and left, creating that 'L'
        border: solid $body-font-color;
        border-width: 0 4px 4px 0;

        content: '';
        height: 1.75rem;
        left: 1.5rem;
        position: absolute;
        top: 0.5rem;

        // Rotate the L 45 degrees to turn it into a checkmark
        transform: rotate(45deg);

        width: 1rem;
      }
    }
  }
}

.card__section {
  @include card-section();
}






.card--fill {
  border: 0;
  overflow: visible;
  padding-top: 1rem;
  position: relative;

  &::before {
    background-color: $primary-color-light;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 0.5s ease;
    width: 100%;
  }

  &:hover {
    &::before {
      transform: scale(1.02);
    }
  }

  .card__section {
    position: relative;
  }
}

.card--fill-alt {
  &::before {
    background-color: $primary-color;
  }
}

.card-wrapper {
  display: block;
  margin-top: 1rem;

  @include breakpoint(medium) {
    margin-top: 0;

    &.card-wrapper--simple {
      margin-bottom: 2rem;
    }
  }

  img {
    display: block;
    margin: 0 auto 1rem;
    width: 33%;
  }
}


