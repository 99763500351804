.bar {
  background-color: $white;
  display: block;
  padding-bottom: 10px;
  padding-top: 10px;

  input[type='text'],
  input[type='search'] {
    box-shadow: none;
  }
}

.bar--tinted {
  background-color: $primary-color-dark;

  a {
    color: $white;
    font-weight: 400;
  }

  input[type='search'],
  .button {
    background-color: rgba($primary-color-light, 0.33);
    border-style: none;
  }
}

.bar--tinted-alt {
  background-color: $dark-gray;
}

.title-bar,
.bar--nav {
  margin-bottom: 4rem;
}

.page-name-login .bar--nav,
.page-name-login .title-bar,
.page-name-index .bar--nav,
.page-name-index .title-bar {
  margin-bottom: 0;
}
