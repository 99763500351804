.callout-form {
  background-color: $primary-color;
  margin-bottom: 2rem;
  padding: 1rem;

  @include breakpoint(medium) {
    background-color: $primary-color-light;
  }

  > a {
    color: $primary-color-dark;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 1.5rem;
    text-decoration: underline;
    text-decoration-skip: ink;
  }

  label {
    color: $body-font-color;
  }

  .heading--small {
    font-size: 14px;
    margin-bottom: 0.5rem;
    margin-top: 0.25rem;
  }

  .button {
    color: $white;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
