@charset 'utf-8';
@import 'settings';
@import 'foundation';
// @import 'motion-ui';
@include foundation-global-styles;
@include foundation-typography;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-title-bar;
@include foundation-menu-icon;
@include foundation-off-canvas;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-accordion-menu;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@import 'components/base';
@import 'components/typo';
@import 'components/bar';

@import 'components/navigation';

@import 'components/lists';

@import 'components/article';
@import 'components/author';
@include foundation-media-object;
@import 'components/smart-card';
@import 'components/card';
@import 'components/accordion';
@import 'components/tableOfContents';

@import 'components/documents';
@import 'components/external';
@import 'components/button';
@import 'components/forms';

@import 'components/util';
@import 'components/slick'; // TODO
@import 'components/javascript';

@import 'components/styleguide';

//@import 'motion-ui';
//@include motion-ui-transitions;
//@include motion-ui-animations;

// @include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
// @include foundation-typography;
// @include foundation-button;
// @include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
// @include foundation-visibility-classes;
// @include foundation-float-classes;
// @include foundation-flex-classes;

.page-main {
  @include breakpoint(medium) {
    min-height: calc(100vh - 149px - 45px);

    .page-name-login & {
      min-height: calc(100vh - 149px - 45px + 3.75rem);
    }
  }
}

.page-section {
  margin-top: 2rem;

  @include breakpoint(medium) {
    margin-top: 6rem;
  }
}

.page-section--intro {
  background-color: $primary-color-light;
  margin-top: 0;

  @include breakpoint(medium) {
    background-image: url('/assets/img/hero_01.jpg');

    background-position: 0 0;
    background-repeat: no-repeat;
    min-height: 640px;
  }

  .button--hollow {
    border-color: $white;
    box-shadow: 0 0 1px $white inset;
  }

  .article--small {
    background-color: rgba($black, 0.075);
    color: white;
    font-size: 14px;
    padding: 1rem;

    @include breakpoint(medium) {
      margin-top: 3rem;
    }

    .article__teaser:hover,
    .article__teaser:focus {
      color: inherit;
    }

    .heading {
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.page-name-login .page-section--intro {
  margin: 0;

  @include breakpoint(medium) {
    min-height: 30rem;
  }

  & + * {
    margin: 0;
  }
}

.page-section--intro__title {
  margin-bottom: 2rem;
  padding-top: 2rem;

  @include breakpoint(medium) {
    padding-top: 5rem;
  }

  .heading {
    border-bottom: 1px solid;
    color: white;
    letter-spacing: 3px;
    margin: 0 auto 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    text-transform: uppercase;

    // todo
    text-shadow: -1px 1px 1px rgba($dark-gray, 0.5);

    @include breakpoint(medium) {
      max-width: 60%;
    }
  }
}

.page-section--special {
  background-color: #E3E3E3;
  padding: 2rem 0;

  @include breakpoint(medium) {
    padding: 3rem 0;
  }
}

.page-section--callout {
  background-color: #F7F7F8;
  padding: 2rem 0;

  @include breakpoint(medium) {
    background-image: url('/assets/img/callout-background.jpg'),
    linear-gradient(to right, #CACED4 0%, #C8CAD0 50%, #C9CACF 75%, #C8CACF 100%);
    padding: 3rem 0;
  }

  .heading {
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
  }
}

.page-section--blue {
  background-color: $primary-color;
  background-image: linear-gradient(to right, $primary-color-medium, $primary-color 95%);
  overflow: hidden;
  padding-top: 3rem;
}

.page-section--callout-author {
  background: #F3F1F5;
  margin-top: 2rem;
  padding: 2rem 0;

  @include breakpoint(medium) {
    margin-bottom: -6rem;
    padding: 4rem 0 5rem;
  }
}




.page-foot {
  @include breakpoint(medium) {
    margin-top: 5rem;
  }
}
