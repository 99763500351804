.smart-card {
  align-items: center;
  box-shadow: -2px 2px 4px 1px rgba($medium-gray, 0.75);
  color: $body-font-color;
  display: flex;
  font-weight: 400;
  margin-bottom: 0.5rem;
  padding: 0.55rem 0.45rem;
  position: relative;

  img {
    align-self: flex-start;
    border: 1px solid #CCCCCC;
    margin-right: 10px;
  }

  span {
    line-height: 1.6;
  }

  strong {
    display: block;
    margin-bottom: 0.75rem;
  }

  &::after {
    border-color: $medium-gray transparent transparent transparent;
    border-style: solid;
    border-width: 10px 10px 0 0;

    content: '';
    display: block;
    height: 0;
    position: absolute;
    right: 0;
    top: 100%;
    width: 0;
  }
}

