.einzug {
  margin-left: $list-side-margin / 2;

  @include breakpoint(medium) {
    margin-left: $list-side-margin;
  }

  .einzug {
    margin-left: $list-nested-side-margin;
  }
}

.aufzaehlung {
  display: inline-block;
  margin-right: 0.5rem;
}

.text {
  margin-bottom: 1.5 * $global-margin;
}

.fett {
  font-weight: 400;
}

.fett_kursiv {
  font-style: italic;
  font-weight: 400;
}

.kursiv {
  font-style: italic;
}

.wichtig,
.wichtigtext {
  border: 1px solid $primary-color;
  padding: 0.5rem;
}

.wichtig {
  background-color: rgba($primary-color, 0.2);
  border-width: 1px 1px 0;
  font-weight: 600;
  margin: 0;
}

.wichtigtext {
  margin-bottom: 1.5 * $global-margin;
}

///////////////////////////////////////////

[id="titleRow"] {
  @extend .container;
}

.ce_headline {
  @extend %heading;
}

.title {
  display: flex;
  flex-direction: column;
  position: relative;

  @include breakpoint(medium) {
    margin-bottom: 2rem;
  }

  .image_container {
    order: 0;
  }

  img {
    display: block;
    user-select: none;
    width: 100%;

    @include breakpoint(medium) {
      margin: 0 auto;
      width: calc(100% - 2rem);
    }
  }

  h2,
  .teaser {
    background-color: $primary-color;
    color: $white;
    order: 1;
    position: relative;
  }

  h2 {
    margin-bottom: 0;
    padding: 0.75rem 1rem 0.125rem;

    @include breakpoint(medium) {
      margin-top: -6rem;

      &::after {
        border-color: transparent transparent rgba($primary-color, 0.85) rgba($primary-color, 0.85);
        border-style: solid;
        border-width: 0.5rem;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        right: 0;
        top: -1rem;
        width: 0;
      }
    }
  }

  .teaser {
    margin-bottom: 2rem;
    padding: 0.125rem 1rem 0.75rem;
  }
}

[id="container"] {
  @include flex-grid-row();
  @extend .medium-unstack;

  font-family: $body-font-family;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;

  @include breakpoint(medium) {
    margin-top: 2rem;
  }
}

[id="main"] {
  @include flex-grid-column(12);

  @include breakpoint(medium) {
    @include flex-grid-column(7);
    @include grid-column-offset(1);
  }

  line-height: 1.6;
  order: 1;

  &.extend-content {
    @include flex-grid-size(10);

    + [id="left"] {
      @include flex-grid-size(1);

      &::after {
        background-image: linear-gradient(to left, white, rgba(255, 255, 255, 0));
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 95%;
      }

      .inside {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;

        .submenu.is-accordion-submenu-parent > a::after {
          content: "";
        }

        .level_2 {
          display: none !important;
        }
      }
    }
  }

  h1 {
    @extend %heading;
  }

  h2, h3, h4, h5, h6 {
    @extend %heading;
    @extend .heading--medium;
  }
}

[id="left"] {
  @include flex-grid-column(12);

  order: 2;
  position: relative;

  @include breakpoint(medium) {
    @include flex-grid-column(4);

    order: 0;
  }

  li, a {
    color: $body-font-color;
    display: block;
  }

  .level_1 {
    margin: 0;

    > li {
      margin-bottom: 1.5rem;

      > a {
        border-bottom: 2px solid $primary-color;
        font-weight: 600;
        margin-bottom: 1rem;
        padding: 0.25rem 0;
      }
    }
  }

  .level_2.is-accordion-submenu {
    margin: 0;

    > .is-accordion-submenu-parent {
      margin-bottom: 0.75rem;

      > a {
        border-bottom: 2px solid $primary-color-dark;
        font-weight: 600;
        padding: 0.25rem 0;
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-top: 0.5rem;

      li {
        margin-left: 1.25rem;
      }
    }
  }

  .submenu.is-accordion-submenu-parent {
    > a {
      padding-right: 2rem;
      position: relative;
    }

    > a::after {
      bottom: 0;
      content: '+';
      font-size: 1.75rem;
      height: 2rem;
      line-height: 2rem;
      margin: auto;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 2rem;
    }

    &[aria-expanded="true"] {
      > a::after {
        content: '-';
      }
    }
  }
}

///////////////////////////////////////////

.einleitung,
#main .SST90_GS-Intro {
  background-color: #ffd6d6;
  margin-bottom: 2rem;
  padding: 1rem 1.5rem;
}

#main figure img.bild_schmal { width: 43%; float: left }
#main td figure img,
#main td figure.image_container img  { border : 0 }

#main figure,
#main figure.image_container {
  margin: 2rem 0;
}

#main figure img,
#main figure.image_container img {
  border: 1px solid #666666;
  display: block;
  padding: 1.5rem;
}

#main figure img.kleiner1 {
  width: 40%;
}

#main figure img.kleiner2 {
  width: 60%;
}

#main figure img.kleiner3 {
  width: 80%;
}

.bildunterschrift, .Tabelle_BU, figcaption {
  font-size: 12px;
  font-style: italic;
  margin-bottom: 2rem;

  #main figure + & {
    margin-top: -1.5rem;
  }
}

.bildunterschrift_schmal {
  font-size: 12px;
  font-style: italic;
  margin-bottom: 20px;
  clear: both
}

.SST90_U4 {
  color: #111111;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  margin: 30px 0 10px 0;
}

#container {
  #main {
    h4.marginalie { font-size: 1em; font-weight: 400; margin: 0; color: #222222 }

    .graukasten {
      background: #dddddd;
      padding: 10px;
      margin-bottom: 50px;

      h4 { margin-top: 0; }
    }

    .kasten40prozent {
      background: #dddddd;
      padding: 10px;
      width: 40%;
      float:left;
      border: 1px solid #ffffff;
      margin-bottom: 50px;
    }
  }
}

#main .SST90_Marke_Tipp_re-S,
#main .SST90_Marke_Tipp_li-S {
  float: left;
  width: 93px;
  height: 22px;
  background: #ff0000;
  color: transparent;
  margin: 0;
  padding: 0;
  background: url("https://www.fachkompetenz-pflege.de/files/redaktion/fachthemen/medizinprodukte-1/wichtig.png") left top no-repeat;
}

#main .SST90_Marke_Tipp_re-S2,
#main .SST90_Marke_Tipp_li-S2 {
  float: left;
  width: 93px;
  height: 22px;
  background: #ff0000;
  color: transparent;
  margin: 0;
  padding: 0;
  background: url("https://www.fachkompetenz-pflege.de/files/redaktion/fachthemen/medizinprodukte-1/beispiel.png") left top no-repeat;
}

#main .SST90_Marke_Tipp_re-S3,
#main .SST90_Marke_Tipp_li-S3 {
  float: left;
  width: 113px;
  height: 22px;
  background: #ff0000;
  color: transparent;
  margin: 0;
  padding: 0;
  background: url("https://www.fachkompetenz-pflege.de/files/redaktion/fachthemen/medizinprodukte-1/praxistipp.png") left top no-repeat;
}


#main .SST90_GS-Num {
  padding: 0 40px;
  margin: 20px 0;
}

#main .bild-maximal { width: 95% }

#main td {
  background: #dddddd;
  vertical-align: top;
}

.tabelle,
#main .Tabelle {
  font-weight: 400;
  padding: 0.5rem 1rem;

  p, ul {
    margin-bottom: 0;
  }

  ul {
    margin-left: 1.25rem;
  }
}

#main .tabelle.kopf,
#main .Tabelle_Kopf {
  background: #888888;
  color: #FFFFFF;
}

.tabelle.zelle
#main .Tabelle_Zelle {
  background: #dddddd;
  vertical-align: top;
}

#main .SST90_Zitat {
  font-weight: 400;
  padding: 10px;
  background-color: #dddddd;
}

#main .memo,
#main .Memo {
  font-weight: 400;
  padding: 20px;
  background-color: #ffdede;
  border: 1px solid #ff0000;
  margin-bottom: 30px;
}

#main .memo h4,
#main .Memo h4 {
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  background-color: #ff0000;
  padding: 1px;
  display: inline;
  margin-right: 10px;
}

#main .memo p,
#main .Memo p { margin: 10px}


#main .SST90_Liste-2 { margin-left: 40px; font-style: italic}

#main .SST90_ZwiTi_1 { color: #333333; font-weight: 400; font-size: 20px }


#main .SST90_IVZ_1 { font-weight: 400; font-size: 1.4em; margin: 30px 0 10px 0}

#main .SST90_IVZ_2 { font-weight: 400; font-size: 1.2em; margin: 30px 0 10px 0; padding-left: 10px; }

#main .SST90_IVZ_3 { font-weight: 300; font-size: 1em; margin: 10px 0 10px 0; padding-left: 20px }

#main .SST90_IVZ_4 { font-weight: 300; font-size: 0.8em; margin: 10px 0 10px 0; padding-left: 30px }


.kapitelnummer {
  font-weight: 400;
  margin-right: 10px;
}


ul.wichtig {
  float: left;
  padding: 0 0 0 20px;
  background: #ff0000;
  background: url("https://www.fachkompetenz-pflege.de/files/redaktion/fachthemen/medizinprodukte-1/wichtig.png") left top no-repeat;
  width: 70%;
  margin-bottom: 20px;
  border-left: 3px solid #CC0000;
}

li span.wichtig2,
span.wichtig {
  border-width: 1px;
  display: block;
  padding-left: 110px;
  position: relative;

  &::before {
    background: white url("https://www.fachkompetenz-pflege.de/files/redaktion/fachthemen/medizinprodukte-1/wichtig.png") center 0.5rem no-repeat;
    content: '';
    display: block;
    height: 100%;
    min-height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100px;
  }
}

li span.wichtig2 {
  margin-bottom: 0.5rem;
  min-height: 2rem;

  &::before {
    background-position: center 1px;
  }
}

span.praxistipp {
  display: block;
  padding: 0 0 0 130px;
  position: relative;

  &::before {
    background: url("https://www.fachkompetenz-pflege.de/files/redaktion/fachthemen/medizinprodukte-1/praxistipp.png") left top no-repeat;
    content: '';
    display: block;
    height: 100%;
    min-height: 20px;
    left: 0;
    position: absolute;
    top: 0.25rem;
    width: 130px;
  }
}




.aufzaehlung {
    padding-left: 30px;
    width: 80%;
}

#main .SST90_Marginalie,
#main .marginalie { font-size: 1em; font-weight: 400; margin: 0; color: #222222; margin-bottom: 10px }

.SST90_Hinterlegung_rosa { background-color: #ffb5b5 }
.SST90_Hinterlegung_grün  { background-color: #bcffb5 }

.themenfeld1 { border: 1px solid #111111; padding: 5px; background: #f19e74 }
.themenfeld2 { border: 1px solid #111111; padding: 5px; background: #feda60 }
.themenfeld3 { border: 1px solid #111111; padding: 5px; background: #a4d09b }
.themenfeld4 { border: 1px solid #111111; padding: 5px; background: #9388c0 }
.themenfeld5 { border: 1px solid #111111; padding: 5px; background: #9dc9ec }
.themenfeld6 { border: 1px solid #111111; padding: 5px; background: #ee694a }

.SST90_U2_Frage { font-size: 20px; color: #ff0000; font-weight: 400; margin-top: 30px; }

.SST90_U3_Frage { font-size: 16px; color: #111111; font-weight: 400; margin-top: 10px; }

.float_left { float: left }
.float_right { float: right }
.clearboth { clear: both }

#container #main h4.bigger { color: #111111; font-size: 18px; font-weight: 400; line-height: 36px; margin: 30px 0 10px 0; text-decoration: underline }

