.social-share {
  strong {
    display: block;
    margin-bottom: 1rem;
  }

  .fa {
    font-size: 2rem;
  }
}

.article {
  margin: 0 0 4rem;
  padding: 0 0.5rem;

  .heading {
    &:not(.heading--small):not(.heading--medium) {
      font-size: rem-calc(28);
    }
  }

  .heading--small {
    margin-top: 3rem;
  }
}

.article--small {
  margin-bottom: 2rem;

  .heading {
    font-size: rem-calc(24);
  }

  .article__author {
    margin-bottom: 1rem;
  }
}

.article__payment {
  margin-bottom: 1.5rem;
  position: relative;

  &::before {
    content: '8JQsvSj+ 8]>gE5A!Ne .f0{d{Jwc}%CA*52B9+|/UULmhdx3k!;M[byte $Pu?OJP7#k|TGG,[EP7 #;S.q)SAd0p!eSEB)xAky};GXvY -#JW Gi-x&XI1Tn+u *g[eO~.R#[YV7-P0 $GJkFBCDyDEP)AD_Opn %<-0P6y?Kn, MswDjFQbm`hI. MDl(nt /Voee?IH@Q2ol{ Jj{}U!5]k cM* n?9% O$$YlPa3k$.IZeLCw2s[0 |e$%]k#05 [)IP*]zWO>';
    display: block;
    filter: blur(3px);
    height: 5rem;
    line-height: 1.7;
    margin-bottom: 1.5rem;
    overflow: hidden;
    padding: 0 3px;
  }

  &::after {
    background-image: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.75) 25%);
    content: '';
    height: 10rem;
    left: 0;
    position: absolute;
    top: -5rem;
    width: 100%;
  }
}

.article__payment-description {
  border: 2px solid $primary-color;
  padding: 1rem 1rem 1.25rem;
  position: relative;

  .button,
  :last-child {
    margin: 0;
  }

}

.article__author {
  color: inherit;
  //display: inline-block;
  font-weight: 600;

  .user-pic {
    margin-right: 0.75rem;
    margin-top: -0.2rem;
  }
}

.article__category {
  color: inherit;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-transform: uppercase;

  .article__author + & {
    &::before {
      content: '|';
      display: inline-block;
      margin-right: 0.15rem;
    }
  }
}

.article__teaser {
  color: inherit;
  display: block;
  font-weight: inherit;

  &:hover {
    color: $body-font-color;
  }
}

.article__banner {
  display: block;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}
